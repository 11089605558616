@import "utils";

#side-bar {
  @include border(right);
  @include custom-scroll-bar();
  width: $side-bar-width;
  height: 100%;
  padding-left: 20px;
  font-weight: bold;
  overflow-x: hidden;
  overflow-y: scroll;

  #header {
    font-size: 18px;
    margin: 10px 0 0;
  }

  #sub-header {
    margin: 5px 0 15px;
  }

  .explorer-element {
    font-size: 16px;
    cursor: pointer;
    user-select: none;

    p {
      margin: 8px 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &:hover {
        color: white;
      }
    }

    i {
      font-size: 20px;
      transform: translateY(3px);
      margin-right: 5px;
    }

    .closed {
      display: none;
    }

    .explorer-children-container {
      @include border(left);
      margin-left: 10px;
      padding-left: 20px;
    }
  }
}
