@import "../utils";

#readme {
  @include custom-scroll-bar();
  margin: 0 auto;
  color: white;
  width: 100%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  overflow-y: scroll;

  #header-row {
    display: flex;
    height: 70px;
    width: 100%;
    justify-content: center;
    align-items: center;

    img {
      height: 90%;
      margin-right: 10px;
    }

    h1 {
      text-align: center;
    }
  }

  hr {
    margin: 0 50px;
    background-color: $border-color;
    border-color: $border-color;
  }

  .center {
    text-align: center;
  }

  h3 {
    margin: 40px 15px;
  }

  & > p {
    margin: 16px 15px;
  }

  span.link {
    @include text-button();
  }

  span.filename {
    background-color: rgba(110, 118, 129, 0.4);
    padding: 1px 4px;
    border-radius: 5px;
    font-family: "Courier New", Courier, monospace;
  }

  #badge-row {
    display: flex;
    justify-content: center;
    font-size: 12px;
    margin: 20px 0 30px;

    .badge {
      display: flex;
      margin: 0 5px;
      overflow: hidden;
      border-radius: 5px;

      &:hover {
        opacity: 0.9;
      }

      p {
        padding: 5px;
        margin: 0;
      }

      .name {
        background-color: #505050;
        box-shadow: inset -5px 0 5px -5px rgba(0, 0, 0, 0.5);
      }

      .value {
        background-color: #929292;
      }
    }
  }
}
