@import "../utils";

#code-block-container {
  @include custom-scroll-bar();
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  & > span {
    @include custom-scroll-bar();
    height: 100%;
  }
}

#image-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}
