@import "utils";

#mobile-container {
  background-color: $bg-color;
  font-family: monospace;
  color: $text-color;
  width: 100vw;
  height: 100vh;

  #default-display {
    height: calc(100% - #{$nav-bar-height} - #{$footer-height});

    #content {
      margin: 100px auto;

      #descriptor,
      h1 {
        font-size: 20px !important;
      }

      h1 {
        margin: 10px 0;
      }

      h2 {
        margin: 25px;
      }
    }
  }

  #resume-display {
    height: calc(100% - #{$nav-bar-height} - #{$footer-height});
  }
}
