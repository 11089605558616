@import "utils";

#terminal {
  @include border(top);
  @include custom-scroll-bar();
  width: 100%;
  max-height: 200px;
  min-height: 200px;
  color: white;
  padding: 20px;
  font-size: 14px;
  overflow-y: scroll;

  p {
    margin: 0;
    width: min-content;
    white-space: nowrap;
  }

  .green {
    color: #c3e88d;
  }

  .blue {
    color: #89ddff;
  }
}
