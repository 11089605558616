@import "utils";

#nav-bar {
  @include border(bottom);
  width: 100%;
  height: $nav-bar-height;
  display: flex;
  align-items: center;

  p {
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    margin: 0 20px;

    &:hover {
      color: white;
    }
  }

  img {
    height: 50%;
    width: auto;
    margin: 0 20px;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }

  #search-bar-container {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
    display: flex;
    user-select: none;

    i.fa-arrow-right,
    i.fa-arrow-left {
      margin: auto 10px;
      cursor: pointer;
      font-size: 19px;

      &:hover {
        color: white;
      }
    }

    i.fa-arrow-right.disabled,
    i.fa-arrow-left.disabled {
      color: $subtext-color;
      cursor: default;
    }

    #search-bar {
      @include border(all);
      text-align: center;
      background-color: $bg-color-light;
      border-width: 1px;
      border-radius: 5px;
      padding: 3px 0;
      width: 100%;
      cursor: pointer;
      font-size: 16px;
      margin: 5px 0;

      i {
        margin: 0 10px;
      }
    }
  }
}
