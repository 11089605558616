@import "../utils";

#default-display {
  display: flex;
  height: 100%;
  width: 100%;

  #content {
    margin: auto;
    width: 100%;
    text-align: center;

    img {
      width: 20%;
      height: auto;
    }

    h1 span {
      color: $highlight-color;
    }

    h2 {
      margin-top: 30px;
      font-style: italic;
    }

    #descriptor {
      font-size: 2em;
      font-weight: bold;
    }

    #resume-view-click {
      @include text-button();
    }
  }
}
