@import "utils";

#footer {
  @include border(top);
  height: $footer-height;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;

  p {
    margin: 0;
    color: $subtext-color;
    font-size: 14px;
    margin-right: 40px;
  }

  .link {
    cursor: pointer;

    &:hover {
      color: white;
    }
  }
}
