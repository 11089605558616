$bg-color: #0f111a;
$bg-color-light: #1d1f28;
$border-color: #2d2a3c;
$text-color: #7e93a2;
$subtext-color: #4b526d;
$highlight-color: #03b0c1;
$highlight-color-hover: #00565e;
$highlight-color-active: #00363b;

$nav-bar-height: 70px;
$footer-height: 30px;
$side-bar-width: 250px;

@mixin border($sides: all) {
  @if $sides == all {
    border: 3px solid $border-color;
  } @else {
    @each $side in $sides {
      border-#{$side}: 3px solid $border-color;
    }
  }

  box-sizing: border-box;
}

@mixin text-button() {
  color: $highlight-color;
  cursor: pointer;
  font-style: italic;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
    color: $highlight-color-hover;
  }

  &:active {
    color: $highlight-color-active;
  }
}

@mixin custom-scroll-bar() {
  &::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgb(63, 63, 63);
  }
}
