@import "utils";

#homepage-container {
  background-color: $bg-color;
  font-family: monospace;
  color: $text-color;
  height: 100vh;
  width: 100vw;
}

#main-content {
  height: calc(100% - #{$nav-bar-height} - #{$footer-height});
  width: 100%;
  display: flex;
}

#main-container {
  width: calc(100vw - #{$side-bar-width});
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
